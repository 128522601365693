<script>
import axios from 'axios';
import {ref, onMounted} from 'vue';

export default {
  name: 'promptManager',
  setup() {
    const Uid = sessionStorage.getItem('uID');

    const promptList = ref([]);
    const totalList = ref(0);
    let presentNum = ref(1);

    const translateEngine = ref('');
    const ab = ref(false);
    const modalFlag = ref(false);
    const createModalFlag = ref(false);
    const editFlag = ref(false);

    const crudNum = ref(0);
    const selectedCustomPrompt = ref({});
    const selectedCustomPromptType = ref('TRANS');
    let tempPrompt = {};

    //for creating prompt
    const promptName = ref('');
    const promptContent = ref('');
    const promptType = ref('TRANS');

    async function createPrompt() {
      try {
        const headers = {
          headers: {
            Uid: Uid
          }
        }

        const rb = {
          cp_type: promptType.value,
          cp_title: promptName.value,
          cp_prompt: promptContent.value
        }

        const res = await axios.post('https://transcreation.lexcodeapi.com/tc/create_cprompt', rb, headers);

        if (res.data.result) {
          alert('프롬프트가 생성되었습니다.');
          location.reload();
        }
      } catch (e) {
        console.error(e);
      }
    }

    async function readPromptList(promptType, page) {
      try {
        selectedCustomPromptType.value = promptType;

        const params = {
          cp_type: promptType,
          page: page == undefined ? 1 : page,
          Uid: Uid,
        }

        const headers = {
          Uid: Uid
        }

        const res = await axios.get(`https://transcreation.lexcodeapi.com/tc/read_cprompt_list`, {params, headers})

        if (res.data.result) {
          promptList.value = res.data.cprompt_list.reverse();
          totalList.value = Math.ceil(res.data.total / 10);
          presentNum.value = page == undefined ? 1 : page;
        }
      } catch (error) {
        console.error(error);
      }
    }

    async function updatePrompt() {
      try {
        const headers = {
          headers: {
            Uid: Uid
          }
        }

        const rb = {
          cp_id: selectedCustomPrompt.value.cp_id,
          cp_title: selectedCustomPrompt.value.cp_title,
          cp_prompt: selectedCustomPrompt.value.cp_prompt
        }

        const res = await axios.put('https://transcreation.lexcodeapi.com/tc/update_cprompt', rb, headers);

        if (res.data.result) {
          alert("프롬프트가 수정 되었습니다.");
          initModal();
        }
      } catch (e) {
        console.error(e);
      }
    }

    async function deletePrompt(promptId) {
      try {
        const confirmDelete = confirm("프롬프트를 삭제하시겠습니까?");

        if (!confirmDelete) {
          return
        }

        const headers = {
          Uid: Uid,
        }

        const rb = {
          cp_id: promptId
        }

        const res = await axios.delete('https://transcreation.lexcodeapi.com/tc/delete_cprompt', {
          headers: headers,
          data: rb
        })

        if (res.data.result) {
          alert("프롬프트가 삭제되었습니다.");
          location.reload();
        }

      } catch (e) {
        console.error(e);
      }
    }

    function formatTimeAgo(dateString) { // Show date about recent update prompt
      const inputDate = new Date(dateString);
      const currentDate = new Date();
      const timeDifference = currentDate - inputDate;
      const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
      const hoursDifference = Math.floor(timeDifference / (1000 * 60 * 60));
      const minutesDifference = Math.floor(timeDifference / (1000 * 60));

      if (daysDifference > 0) {
        return `${daysDifference}일 전`;
      } else if (hoursDifference > 0) {
        return `${hoursDifference}시간 전`;
      } else {
        return `${minutesDifference}분 전`;
      }
    }

    function findPrompt(id, isEditable) {
      modalFlag.value = true;
      editFlag.value = isEditable;
      selectedCustomPrompt.value = promptList.value.find(item => item.cp_id === id);
      tempPrompt = JSON.parse(JSON.stringify(selectedCustomPrompt.value));
      console.log("tempPrompt1: ", tempPrompt);
      console.log("selected1: ", selectedCustomPrompt.value);
    }

    async function editPrompt() {
      if (!editFlag.value) {
        editFlag.value = !editFlag.value;
        return;
      }

      if (tempPrompt.cp_title == selectedCustomPrompt.value.cp_title && tempPrompt.cp_prompt == selectedCustomPrompt.value.cp_prompt) {
        editFlag.value = !editFlag.value;
        return;
      }

      await updatePrompt();
    }

    function initModal() {
      modalFlag.value = false;
      editFlag.value = false;

      readPromptList(selectedCustomPromptType.value);
    }

    function initModalForCreatingPrompt() {
      createModalFlag.value = false;
      promptName.value = '';
      promptContent.value = '';
      promptType.value = 'TRANS';
    }

    onMounted(async () => {
      await readPromptList('TRANS');
    })

    return {
      ab,
      Uid,
      translateEngine,
      crudNum,
      selectedCustomPrompt,
      selectedCustomPromptType,
      promptName,
      promptContent,
      promptType,
      promptList,
      modalFlag,
      createModalFlag,
      editFlag,
      totalList,
      presentNum,

      createPrompt,
      readPromptList,
      deletePrompt,
      formatTimeAgo,
      findPrompt,
      initModal,
      initModalForCreatingPrompt,
      editPrompt,
    }
  }
}
</script>

<template>
  <div id="#promptManager">
    <div class="prompt_manager_wrapper">
      <h3 class="title">프롬프트 관리</h3>

      <div class="sort_wrapper">
        <div class="sort" :class="{'activate' : selectedCustomPromptType === 'TRANS'}"
             @click=" readPromptList('TRANS') ">
          TRANS
        </div>
        <div class="sort" :class="{'activate' : selectedCustomPromptType === 'KEYWORD'}"
             @click=" readPromptList('KEYWORD') ">
          KEYWORD
        </div>
        <div class="sort" @click="createModalFlag = true">
          프롬프트 생성
        </div>
      </div>

      <div class="prompt_table_row table_header">
        <div class="prompt_index">ID</div>
        <div class="prompt_type">타입</div>
        <div class="prompt_name">프롬프트명</div>
        <div class="prompt_update">최근 수정일</div>
        <div class="prompt_edit">수정</div>
        <div class="prompt_delete">삭제</div>
      </div>

      <div class="prompt_table_row table_list" v-for="(promptList, index) in promptList" :key="index">
        <div class="prompt_id" @click="findPrompt(promptList.cp_id, false)">{{ promptList.cp_id }}</div>
        <div class="prompt_type" @click="findPrompt(promptList.cp_id, false)">{{ promptList.cp_type }}</div>
        <div class="prompt_name" @click="findPrompt(promptList.cp_id, false)">{{ promptList.cp_title }}</div>
        <div class="prompt_update" @click="findPrompt(promptList.cp_id, false)">{{
            formatTimeAgo(promptList.updated_at)
          }}
        </div>
        <div class="prompt_edit" @click="findPrompt(promptList.cp_id, true)">
          <img src="../assets/img/btn_edit.png" alt="btn_edit">
        </div>
        <div class="prompt_delete" @click="deletePrompt(promptList.cp_id)">
          <img src="../assets/img/ico_delete.png" alt="ico_delete">
        </div>
      </div>

      <div class="prompt_table_row empty" v-if="promptList.length === 0">프롬프트가 없습니다.</div>
    </div>

    <div class="page_nation">
      <span v-for="index in totalList" :key="index" class="num" :class="{ 'selected' : index === presentNum }" @click="readPromptList(selectedCustomPromptType, index)">{{ index }}</span>
    </div>

    <!--    프롬프트 조회, 수정 모달-->
    <div class="prompt_modal_wrapper" v-if="modalFlag">
      <div class="prompt_edit_container">
        <div class="title_wrap">
          <div class="title_left">
            <h3 @click="ab = !ab">프로젝트</h3>
            <img :class="{ 'activate' : editFlag }" src="../assets/img/btn_edit.png" alt="btn_edit"
                 @click="editPrompt"/>
          </div>
          <img src="../assets/img/close.png" alt="close" @click="initModal"/>
        </div>

        <div class="prompt_edit_row" v-show="ab">
          <label>번역엔진</label>
          <select v-model="translateEngine">
            <option value="claude_opus_custom_self_direction">Claude opus self direction</option>
            <option value="claude_sonnet_custom_self_direction">Claude sonnet self direction</option>
            <option value="claude_opus">Claude3 OPUS</option>
            <option value="claude_sonnet">Claude3 SONNET</option>
            <option value="claude_opus_merge">Claude3 OPUS Merge</option>
            <option value="claude_sonnet_merge">Claude3 SONNET Merge</option>
            <option value="deepl">DeepL</option>
            <option value="google">GOOGLE</option>
            <option value="gpt35_del_style">GPT-3.5 Del Style</option>
            <option value="gpt35merge">GPT-3.5 Merge</option>
            <option value="gpt35">GPT-3.5 Turbo</option>
            <option value="gpt35_custom_self_direction">GPT-3.5 self direction</option>
            <option value="gpt4_del_style">GPT-4 Del Style</option>
            <option value="gpt4merge">GPT-4 Merge</option>
            <option value="gpt4">GPT-4 Turbo</option>
            <option value="gpt4_custom_self_direction">GPT-4 self direction</option>
            <option value="gpt4_del_style_custom_mcst">GPT-4 문체부</option>
            <option value="gpt4_del_style_custom_law">GPT-4 법률</option>
            <option value="gpt4o">GPT-4o</option>
            <option value="gpt4o_merge">GPT-4o Merge</option>
            <option value="papago">Papago</option>
            <option value="claude_opus_custom_hurom">휴롬엘에스 opus</option>
            <option value="gpt4o_custom_hurom">휴롬엘에스 GPT4o</option>
            <option value="gpt4_custom_hurom">휴롬엘에스 GPT4</option>
          </select>
        </div>

        <div class="prompt_edit_row">
          <label for="promptName">프롬프트 이름</label>
          <input id="promptName" type="text" placeholder="프롬프트 제목을 입력하세요." :readonly="!editFlag"
                 v-model="selectedCustomPrompt.cp_title"/>
        </div>

        <div class="prompt_edit_row">
          <label>프롬프트 내용</label>
          <textarea :class="{ 'readonly' : !editFlag }" placeholder="프롬프트 내용을 입력하세요." :readonly="!editFlag"
                    v-model="selectedCustomPrompt.cp_prompt"></textarea>
        </div>

        <div class="prompt_edit_row prompt_buttons" v-show="editFlag">
          <button @click="editPrompt">프롬프트 저장</button>
          <button @click="initModal">취소</button>
        </div>
      </div>
    </div>

    <!--    프롬프트 생성 모달-->
    <div class="prompt_modal_wrapper" v-if="createModalFlag">
      <div class="prompt_edit_container">
        <div class="title_wrap">
          <div class="title_left">
            <h3>프롬프트 생성</h3>
          </div>
          <img src="../assets/img/close.png" alt="close" @click="initModalForCreatingPrompt"/>
        </div>

        <div class="prompt_edit_row">
          <label for="promptName">프롬프트 타입</label>
          <select v-model="promptType">
            <option value="TRANS">TRANS</option>
            <option value="KEYWORD">KEYWORD</option>
          </select>
        </div>

        <div class="prompt_edit_row">
          <label for="promptName">프롬프트 이름</label>
          <input id="promptName" type="text" placeholder="프롬프트 제목을 입력하세요." v-model="promptName"/>
        </div>

        <div class="prompt_edit_row">
          <label>프롬프트 내용</label>
          <textarea placeholder="프롬프트 내용을 입력하세요." v-model="promptContent"></textarea>
        </div>

        <div class="prompt_edit_row prompt_buttons">
          <button @click="createPrompt">프롬프트 생성</button>
          <button @click="initModalForCreatingPrompt">취소</button>
        </div>
      </div>
    </div>
  </div>
</template>

<style></style>
