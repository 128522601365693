<script>
export default {
  name: 'commonHeader',
  props: {
    window: Object
  },
}
</script>

<template>
  <div class="header">
    <h1>
      <router-link to="/">
        <img src="../assets/img/logo.png" />
      </router-link>
      <a class="go_feedback_page"
        href="https://www.notion.so/dnbdivision/449c43ac2607452b85313e1622466666?v=a3855b15bac349cdb07ed6014d6597a6"
        target="_blank">문의하기</a>
    </h1>
  </div>
</template>