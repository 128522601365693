<script>
export default {
  name: 'commonFooter',
  props: {
      window: Object
  },
}
</script>

<template>
    <div>
      <h1>FOOTER</h1>
    </div>
</template>