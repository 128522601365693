import { createRouter, createWebHistory } from 'vue-router'
import translateStart from '../views/translateStart.vue'
import translateResult from '../views/translateResult.vue'
import projectList from '../views/projectList.vue'
import creationView from '../views/creation.vue'
import promptManager from '../views/promptManager.vue'

const routes = [
  {
    path: '/',
    name: 'projectList',
    component: projectList
  },
  {
    path: '/translate-creation',
    name: 'translateStart',
    component: translateStart
  },
  { 
    path: '/translate',
    name: 'translateResult',
    component: translateResult,
    props: true
  },
  {
    path: '/creation',
    name: 'creationView',
    component: creationView
  },
  {
    path: '/promptmanager',
    name: 'promptManager',
    component: promptManager
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
