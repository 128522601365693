import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import commonHeader from './components/commonHeader.vue'
import commonFooter from './components/commonFooter.vue'
import './assets/css/app.css'
import axios from 'axios'
import VueClipboard from 'vue-clipboard2'
// import VueSocketIO from 'vue-socket.io';

// const BASE_SERVER_URL = 'wss://transcreation.lexcode.com/tc';
// const socket = new VueSocketIO({
//   debug: true,
//   connection: BASE_SERVER_URL,
// });


const app = createApp(App)
// app.provide('$BASE_SERVER_URL', BASE_SERVER_URL);
// app.provide('$socket', socket);

app.component('common-header', commonHeader)
app.component('common-footer', commonFooter)
app.config.globalProperties.$axios = axios

app.use(router).use(VueClipboard)

app.mount('#app')

export const segmentTypeForPrompt = `
(#Instruction)
1. As a professional translator, translate the “input_text” into {tar_lang} based on the following guidelines:
•   Make sure to understand and work in the context of the “input_text.”
•   Avoid literal translation and ensure naturalness, readability, consistency, and accuracy at your best effort.  

2. As a highly skilled writer and linguist, refine the translated text based on the following guidelines:  
•   Replace terms with synonyms and/or commonly used terms to improve readability.
•   Use idioms that are familiar to native Korean readers to improve readability.
•   Merge or separate sentences and/or ideas to improve readability.
•   Rephrase and/or paraphrase while ensuring that the information and separation of paragraphs remain intact.  
•   Simplifying sentences is recommended, as long as the "input_text" remains informative.
•   Where recommended, add conjunctions and clarify pronouns to improve readability.
•   Do not excessively omit information, exaggerate, or assume if not indicated in the "input_text."
•   Maintain the format and separation of the "input_text" as much as possible. These include chapters, headings, line breaks, and paragraphs.
 
3. Only provide the "refined translation" of the “input_text” to save tokens.
 
4. Respond in json format with 'trans' key.
 
\` \` \`
input_text:
{origin_text}
\`\`\`
Target Language:
{tar_lang}
\`\`\`
respond:
`;

export const mergeTypeForPrompt = `Your task is to translate the 'Target Text' into {tar_lang}.
 
(#instructions)
- Translate from {tar_lang} to natural expressions.
- '====Number====' is used to distinguish segments. Keep it in the same format.
- The original text of each segment must be translated into {tar_lang}.
- The number of segments appearing in the original text and the translated text must be the same. You must guarantee this.
- Take care to ensure that each segment is translated without missing anything.
- If there is a tag wrapped with <> in a segment of the original text, do not translate this tag and keep it in the translation as well.
\` \` \`
Target Language:
{tar_lang}
 
You must translate into {tar_lang}.
 
I'll put the data to be translated in the format below so you can clearly understand the scope.
<Target Text Start>
<Target Text End>
\` \` \`
<Target Text Start>
{input_data}
<Target Text End>
\` \` \`
Now you should respond, Start with 'My result:'.`;