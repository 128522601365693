<script>
// import { ref } from 'vue';

export default {
  name: "translateResult",
  props: {
    project_code: String,
  },
  data() {
    return {
      quickBtn: false,
      expertBtn: false,
      uid: "",
      projectCode: "",
      sourceLanguage: "",
      targetLanguage: "",
      glossaryResultData: [],
      showSaveGlo: [],
      showSaveTrans: [],
      copyRecommend: [],
      translateResultData: [],
      transSort: "row_num",
      transOrderBy: "asc",
      quickBtnEngine: "",
      quickBtnTarget: "",
      quickBtnGlossaryYN: false,
      quickBtnTransYN: false,
      overlay: false,
    };
  },
  // setup() {
  //   const isToggled = ref(false);

  //   const toggle = () => {
  //     isToggled.value = !isToggled.value;
  //   };

  //   return {
  //     isToggled,
  //     toggle,
  //   };
  // },
  methods: {
    async fetchTranslate() {
      try {
        const response = await this.$axios.get(
          `https://transcreation.lexcodeapi.com/tc/read_project/${this.projectCode}`,
          {
            params: {
              sort: this.transSort,
              order_by: this.transOrderBy, // [desc, asc]
            },
            headers: {
              Uid: this.uid,
            },
          }
        );
        console.log(response.data);

        if (response.data.result === true) {
          const projectInfo = response.data.project;
          this.sourceLanguage = projectInfo.src_lang;
          this.targetLanguage = projectInfo.tgt_lang;
          this.glossaryResultData = projectInfo.glo_datas;
          this.translateResultData = projectInfo.trans_datas;
          this.quickBtnEngine = projectInfo.trans_engine;
          this.quickBtnTarget = projectInfo.tgt_lang;
        } else {
          console.error("Server returned false result:", response.data);
        }
      } catch (error) {
        console.error("Error fetching data from server:", error);
      }
    },
    isItemActive(duplicateInfo, currentItem) {
      // 해당 item이 activeIndex와 같은지 여부를 판단하여 boolean 값을 반환
      console.log(duplicateInfo, currentItem);
      return duplicateInfo.indexOf(currentItem);
    },
    toggleSortTable(sort) {
      this.transSort = sort;
      this.transOrderBy = this.transOrderBy === "asc" ? "desc" : "asc";
      this.fetchTranslate();
    },
    async updateGlo(index, glo_fix, pgd_id) {
      try {
        const response = await this.$axios.put(
          "https://transcreation.lexcodeapi.com/tc/update_glo_datas",
          [
            {
              pgd_id: pgd_id,
              glo_fix: glo_fix,
            },
          ],
          {
            headers: {
              Uid: this.uid,
              ProjectCode: this.projectCode,
              "Content-Type": "application/json",
            },
          }
        );

        console.log(response.data);

        if (response.data.result === true) {
          this.showSaveGlo[index] = true;

          // 1초 후에 'save' 클래스를 숨깁니다.
          setTimeout(() => {
            this.showSaveGlo[index] = false;
          }, 1000);
        } else {
          console.error("Server returned false result:", response.data);
        }
      } catch (error) {
        console.error("Error fetching data from server:", error);
      }
    },

    async handleKeyDownForGlo(event, index, glo_fix, pgd_id) {
      if (event.shiftKey && event.key === "Enter") {
        event.preventDefault();
        const textArea = this.$refs.textAreaGlo;
        const textAreaGlo = textArea[index];
        const start = textAreaGlo.selectionStart;
        const end = textAreaGlo.selectionEnd;

        const result =
          textAreaGlo.value.substring(0, start) +
          "\n" +
          textAreaGlo.value.substring(end);

        textAreaGlo.value = result;
        textAreaGlo.selectionStart = textAreaGlo.selectionEnd = start + 1;

        for (let i = 0; i < this.glossaryResultData.length; i++) { // 선형적 방식이라 속도 성능 개선이 필요함.
          if (this.glossaryResultData[i].pgd_id === pgd_id) {
            this.glossaryResultData[i].glo_fix = result;
            break;
          }
        }
      } else if (event.key === "Enter") {
        event.preventDefault();
        await this.updateGlo(index, glo_fix, pgd_id);
      }
    },

    async handleKeyDownForTrans(event, index, trans_fix, ptd_id) {
      if (event.shiftKey && event.key === "Enter") {
        event.preventDefault();
        const textArea = this.$refs.textAreaTrans;
        const textAreaTrans = textArea[index];
        const start = textAreaTrans.selectionStart;
        const end = textAreaTrans.selectionEnd;

        const result =
          textAreaTrans.value.substring(0, start) +
          "\n" +
          textAreaTrans.value.substring(end);

        textAreaTrans.value = result;
        textAreaTrans.selectionStart = textAreaTrans.selectionEnd = start + 1;

        for (let i = 0; i < this.translateResultData.length; i++) { // 선형적 방식이라 속도 성능 개선이 필요함.
          if (this.translateResultData[i].ptd_id === ptd_id) {
            this.translateResultData[i].trans_fix = result;
            break;
          }
        }
      } else if (event.key === "Enter") {
        event.preventDefault();
        await this.updateTrans(index, trans_fix, ptd_id);
      }
    },
    async updateTrans(index, trans_fix, ptd_id) {
      try {
        const response = await this.$axios.put(
          "https://transcreation.lexcodeapi.com/tc/update_trans_datas",
          [
            {
              ptd_id: ptd_id,
              trans_fix: trans_fix,
            },
          ],
          {
            headers: {
              Uid: this.uid,
              ProjectCode: this.projectCode,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.data.result === true) {
          this.showSaveTrans[index] = true;
          // 1초 후에 'save' 클래스를 숨깁니다.
          setTimeout(() => {
            this.showSaveTrans[index] = false;
          }, 1000);
        } else {
          console.error("Server returned false result:", response.data);
        }
      } catch (error) {
        console.error("Error fetching data from server:", error);
      }
    },
    quickBtnToggle() {
      if (this.quickBtn == true) {
        this.quickBtn = false;
      } else {
        this.quickBtn = true;
      }
    },
    expertBtnToggle() {
      if (this.expertBtn == true) {
        this.expertBtn = false;
      } else {
        this.expertBtn = true;
      }
    },
    async fetchQuickFx() {
      this.quickBtn = false;
      this.overlay = true;

      try {
        const response = await this.$axios.post(
          `https://transcreation.lexcodeapi.com/tc/generate_trans_data_for_edit`,
          {
            tgt_lang: this.quickBtnTarget,
            trans_engine: this.quickBtnEngine,
            apply_glo: this.quickBtnGlossaryYN,
            retry_trans: this.quickBtnTransYN,
          },
          {
            headers: {
              Uid: this.uid,
              ProjectCode: this.projectCode,
            },
          }
        );
        console.log(response.data);

        if (response.data.result === true) {
          await this.fetchTranslate();
        } else {
          console.error("Server returned false result:", response.data);
        }
      } catch (error) {
        console.error("Error fetching data from server:", error);
      } finally {
        this.overlay = false;
        window.location.reload();
      }
    },
    copyGlossary(text) {
      this.$copyText(text).then(
        (e) => {
          console.log("Text Copied!", e);
        },
        (e) => {
          console.error("Unable to copy text", e);
        }
      );
    },
    exportFile(item) {
      return (
        "https://transcreation.lexcodeapi.com/tc/download/" +
        this.uid +
        "/" +
        this.projectCode +
        "/" +
        item
      );
    },
  },
  async mounted() {
    this.uid = sessionStorage.getItem("uID");
    this.projectCode = this.$route.query.project_code;
    await this.fetchTranslate();
    this.glossaryResultData.forEach((item, index) => {
      this.$refs["gloRef_" + index][0].showSaveGlo = false; // 초기에는 false로 설정
    });
    this.translateResultData.forEach((item, index) => {
      this.$refs["trRef_" + index][0].showSaveTrans = false; // 초기에는 false로 설정
    });
  },
};
</script>

<template>
  <div class="translateView">
    <div class="process_wrap">
      <div class="step">
        <p class="num">1</p>
        <p class="process">설정</p>
      </div>
      <div class="step active">
        <p class="num">2</p>
        <p class="process" @click="quickBtnToggle()">
          번역<img
            src="../assets/img/chevron.png"
            :class="{ rotate: quickBtn }"
          />
        </p>
        <div class="quickBtn_wrap" v-if="quickBtn">
          <fieldset>
            <label>번역엔진</label>
            <select v-model="quickBtnEngine">
              <option value="claude_opus_custom_self_direction">
                Claude opus self direction
              </option>
              <option value="claude_sonnet_custom_self_direction">
                Claude sonnet self direction
              </option>
              <option value="claude_opus">Claude3 OPUS</option>
              <option value="claude_sonnet">Claude3 SONNET</option>
              <option value="claude_opus_merge">Claude3 OPUS Merge</option>
              <option value="claude_sonnet_merge">Claude3 SONNET Merge</option>
              <option value="claude35_sonnet">Claude3.5 SONNET</option>
              <option value="deepl">DeepL</option>
              <option value="google">GOOGLE</option>
              <option value="gpt35_del_style">GPT-3.5 Del Style</option>
              <option value="gpt35merge">GPT-3.5 Merge</option>
              <option value="gpt35">GPT-3.5 Turbo</option>
              <option value="gpt35_custom_self_direction">
                GPT-3.5 self direction
              </option>
              <option value="gpt4_del_style">GPT-4 Del Style</option>
              <option value="gpt4merge">GPT-4 Merge</option>
              <option value="gpt4">GPT-4 Turbo</option>
              <option value="gpt4_custom_self_direction">
                GPT-4 self direction
              </option>
              <option value="gpt4_del_style_custom_mcst">GPT-4 문체부</option>
              <option value="gpt4_del_style_custom_law">GPT-4 법률</option>
              <option value="gpt4o">GPT-4o</option>
              <option value="gpt4o_merge">GPT-4o Merge</option>
              <option value="papago">Papago</option>
              <option value="claude_opus_custom_hurom">휴롬엘에스 opus</option>
              <option value="gpt4o_custom_hurom">휴롬엘에스 GPT4o</option>
              <option value="gpt4_custom_hurom">휴롬엘에스 GPT4</option>
            </select>
          </fieldset>
          <fieldset>
            <label>용어집 반영</label>
            <input type="checkbox" v-model="quickBtnGlossaryYN"/>
          </fieldset>
          <fieldset>
            <label>문장 재번역</label>
            <input type="checkbox" v-model="quickBtnTransYN" />
          </fieldset>
          <p class="btn" @click="fetchQuickFx()">실행</p>
        </div>
      </div>
      <div class="step">
        <p class="num">3</p>
        <router-link
          :to="`/creation?project_code=${projectCode}&trg_lang=${targetLanguage}`"
          class="process"
          >Creation</router-link
        >
      </div>
      <div class="step">
        <p class="num">4</p>
        <p class="process" @click="expertBtnToggle()">
          추출<img
            src="../assets/img/chevron.png"
            :class="{ rotate: expertBtn }"
          />
        </p>
        <div class="quickBtn_wrap export" v-if="expertBtn">
          <a :href="exportFile('origin')">
            <label>원문 파일</label>
            <img src="../assets/img/download_icon.png" />
          </a>
          <a :href="exportFile('trans')">
            <label>번역문 파일</label>
            <img src="../assets/img/download_icon.png" />
          </a>
          <a :href="exportFile('glo')">
            <label>용어집 파일</label>
            <img src="../assets/img/download_icon.png" />
          </a>
        </div>
      </div>
    </div>
    <div class="notice">
      <p>* 원어 문장, 번역어 문장, 번역어 용어 부분만 수정 가능합니다.</p>
      <p>* 수정 후 엔터를 누르면 저장이 됩니다.</p>
      <p>* Glo 추천용어는 해당 용어를 누르면 복사가 됩니다.</p>
    </div>
    <div class="overlay" v-if="overlay">
      <div class="loading">
        <span class="dot"></span>
        <span class="dot"></span>
        <span class="dot"></span>
      </div>
    </div>
    <div class="table trans_table" ref="transTable">
      <p class="total_seg">Total Segment: {{ translateResultData.length }}</p>
      <div class="th">
        <p @click="toggleSortTable('row_num')">
          NO.<span v-if="transSort == 'row_num'">{{
            transOrderBy == "asc" ? "↑" : "↓"
          }}</span>
        </p>
        <p @click="toggleSortTable('trans_source')">
          {{ sourceLanguage
          }}<span v-if="transSort == 'trans_source'">{{
            transOrderBy == "asc" ? "↑" : "↓"
          }}</span>
        </p>
        <p @click="toggleSortTable('trans_fix')">
          {{ targetLanguage
          }}<span v-if="transSort == 'trans_fix'">{{
            transOrderBy == "asc" ? "↑" : "↓"
          }}</span>
        </p>
        <p @click="toggleSortTable('duplicate_info')">
          중복률<span v-if="transSort == 'duplicate_info'">{{
            transOrderBy == "asc" ? "↑" : "↓"
          }}</span>
        </p>
      </div>
      <div class="tbody">
        <div
          class="tr"
          v-for="(transData, transIndex) in translateResultData"
          :key="transIndex"
          :ref="'trRef_' + transIndex"
        >
          <p>{{ transData.row_num }}</p>
          <textarea
            v-model="transData.trans_source"
            ref="textAreaOrigin"
            :class="sourceLanguage.substring(0, 7)"
          ></textarea>
          <textarea
            v-model="transData.trans_fix"
            ref="textAreaTrans"
            @keydown="
              handleKeyDownForTrans(
                $event,
                transIndex,
                transData.trans_fix,
                transData.ptd_id
              )
            "
            :class="{
              saveTrans: showSaveTrans[transIndex],
              saved: showSaveTrans[transIndex],
              [targetLanguage.substring(0, 7)]: true,
            }"
          ></textarea>
          <div>
            <p
              v-for="(rowItem, index3) in transData.duplicate_info.row || []"
              :key="index3"
            >
              {{ rowItem }}
              <span v-if="index3 < transData.duplicate_info.row.length"
                >번</span
              >
            </p>
            {{
              transData.duplicate_info.per == 0
                ? ""
                : transData.duplicate_info.per
            }}
            <span v-if="transData.duplicate_info.per !== 0">%일치</span>
          </div>
        </div>
      </div>
    </div>
    <div ref="glossaryTable" class="table glossary_table">
      <div class="th">
        <p>원문용어</p>
        <p>번역된 용어</p>
        <p>Glo 추천용어(출처)</p>
      </div>
      <div class="tbody">
        <div
          class="tr"
          v-for="(source, index) in glossaryResultData"
          :key="index"
          :ref="'gloRef_' + index"
        >
          <textarea
            v-model="source.glo_source"
            readonly
            :class="sourceLanguage.substring(0, 7)"
          ></textarea>
          <textarea
            v-model="source.glo_fix"
            ref="textAreaGlo"
            @keydown="handleKeyDownForGlo($event, index, source.glo_fix, source.pgd_id)"
            :class="{
              saveGlo: showSaveGlo[index],
              [targetLanguage.substring(0, 7)]: true,
            }"
          ></textarea>
          <div>
            <li
              v-for="(recommend, index) in source.glo_recommend"
              :key="index"
              @click="copyGlossary(recommend[0])"
            >
              <span>{{ recommend[0] }}</span>
              <span>{{ recommend[1] }}</span>
            </li>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
