<script>
import { computed, ref, onMounted, watchEffect } from 'vue';
import { useRouter } from 'vue-router'
import axios from 'axios';

export default {
  name: 'projectList',
  setup() {
    const isLogin = ref(false);
    const user_name = ref('');
    const user_password = ref('');
    const bound_type = ref('inbound');
    const uid = ref('');
    const search_by = ref('project_name');
    const search_text = ref('');
    const page = ref();
    const limit = ref('100000000000');
    const projectList = ref([]);
    const filteredResults = ref([]);
    const router = useRouter();
    const file_link = "https://transcreation.lexcodeapi.com/tc/download/";
    const itemsPerPage = 10 // 페이지 당 표시할 항목 수
    const currentPage = ref(1)

    const login = async () => {
      try {
        const response = await axios.post('https://transcreation.lexcodeapi.com/tc/user/login', {
          username: user_name.value,
          password: user_password.value,
          bound_type: bound_type.value
        });

        console.log(response.data);
        if (response.data.result == true) {
          isLogin.value = true;
          uid.value = response.data.uid;
          sessionStorage.setItem('loginYN', 'true');
          sessionStorage.setItem('uID', uid.value);
          sessionStorage.setItem('user_name', user_name.value);
        } else {
          alert("아이디나 비밀번호가 틀렸습니다.");
        }
      } catch (error) {
        // 오류 처리
        console.error(error);
      } finally {
        fetchList();
      }
    };

    const logout = async () => {
      try {
        sessionStorage.removeItem('loginYN');
        sessionStorage.removeItem('uID');
        sessionStorage.removeItem('user_name');
      } catch (error) {
        // 오류 처리
        console.error(error);
      } finally {
        window.location.reload();
      }
    };

    const fetchList = async () => {
      try {
        const response = await axios.get(
          'https://transcreation.lexcodeapi.com/tc/read_project_list',
          {
            params: {
              search_by: search_by.value,
              search_text: search_text.value,
              page: page.value,
              limit: limit.value,
            },
            headers: {
              'Uid': uid.value,
            },
          }
        );

        console.log(response.data);
        if (response.data.result == true) {
          projectList.value = response.data.project_list;
          projectList.value = response.data.project_list.map(item => {
            return {
              ...item,
              created_at: formatDate(item.created_at),
              updated_at: formatDate(item.updated_at),
              src_lang: mapLanguageCode(item.src_lang),
              tgt_lang: mapLanguageCode(item.tgt_lang),
            };
          });
        }
      } catch (error) {
        // 오류 처리
        console.error(error);
      }
    };
    // 날짜 포맷 변경
    const formatDate = (inputDate) => {
      const date = new Date(inputDate);
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      return `${year}-${month}-${day}`;
    };
    // 언어 포맷 변경
    const mapLanguageCode = (langCode) => {
      switch (langCode) {
        case 'Korean':
          return '한';
        case 'English':
          return '영';
        case 'Chinese(Simplified)':
          return '중';
        case 'Japanese':
          return '일';
        case 'Spanish':
          return '스';
        case 'Tagalog':
          return '필';
        // 다른 언어에 대한 처리도 필요하다면 여기에 추가
        default:
          return langCode;
      }
    };
    const mapEngineName = (engineName) => {
      switch (engineName) {
        case 'deepl':
          return 'DeepL';
        case 'gpt35':
          return 'GPT-3.5 Turbo';
        case 'gpt4':
          return 'GPT-4 Turbo';
        case 'google':
          return 'Google';
        case 'papago':
          return 'Papago';
        case 'gpt35merge':
          return 'GPT-3.5 Merge';
        case 'gpt4merge':
          return 'GPT-4 Merge';
        case 'gpt35_del_style':
          return 'GPT-3.5 Del Style';
        case 'gpt4_del_style':
          return 'GPT-4 Del Style';
        case 'gpt4_del_style_custom_mcst':
          return 'GPT-4 문체부';
        case 'gpt4_del_style_custom_law':
          return 'GPT-4 법률';
        default:
          return engineName;
      }
    };
    // 새 프로젝트 이동
    const pushProject = (item) => {
      router.push({
        path: '/translate',
        query: {
          project_code: item || ''
        }, // 값이 없는 경우 빈 문자열로 설정
      });
    };
    // 리스트에서 파일 다운로드 링크
    const downloadFile = (projectCode, document) => {
      const fileLink = file_link + uid.value + '/' + projectCode + '/' + document;
      return fileLink;
    };
    const formatCount = (count) => {
      const format = count.toLocaleString();
      return format
    };

    // 검색 필터
    watchEffect(() => {
      const searchText = search_text.value || '';
      filteredResults.value = projectList.value.filter(result => {
        const resultValue = result?.[search_by.value] || '';
        currentPage.value = 1
        return resultValue.includes(searchText);
      });
    });

    // 페이지 각각 장수 계산
    const paginatedData = computed(() => {
      console.log(filteredResults.value.length)
      const startIndex = (currentPage.value - 1) * itemsPerPage
      const endIndex = startIndex + itemsPerPage
      return filteredResults.value.slice(startIndex, endIndex)
    })
    // 전체 페이지 계산
    const totalPages = computed(() => Math.ceil(filteredResults.value.length / itemsPerPage))

    // 현재 페이지 주변에 표시할 페이지 번호 계산
    const visiblePages = computed(() => {
      const pageRange = 1 // 현재 페이지를 기준으로 양쪽에 표시할 페이지 번호 개수
      const totalVisiblePages = 4 // 처음과 마지막을 제외한 표시될 페이지 번호 개수
      const currentPageIndex = currentPage.value - 1
      const visiblePages = []

      if (totalPages.value <= totalVisiblePages + 2) {
        // 페이지 번호가 모두 표시 가능한 경우
        visiblePages.push(...Array.from({ length: totalPages.value }, (_, index) => index + 1))
      } else if (currentPageIndex < totalVisiblePages - pageRange) {
        // 현재 페이지가 처음 페이지 근처에 위치한 경우
        visiblePages.push(...Array.from({ length: totalVisiblePages + 1 }, (_, index) => index + 1))
        visiblePages.push('...')
        visiblePages.push(totalPages.value)
      } else if (currentPageIndex >= totalPages.value - 2 - pageRange) {
        // 현재 페이지가 마지막 페이지 근처에 위치한 경우
        visiblePages.push(1)
        visiblePages.push('...')
        visiblePages.push(...Array.from({ length: totalVisiblePages + 1 }, (_, index) => totalPages.value - totalVisiblePages + index))
      } else {
        // 현재 페이지가 중간에 위치한 경우
        visiblePages.push(1)
        visiblePages.push('...')
        visiblePages.push(...Array.from({ length: pageRange * 2 + 1 }, (_, index) => currentPageIndex - pageRange + index + 1))
        visiblePages.push('...')
        visiblePages.push(totalPages.value)
      }

      return visiblePages
    })

    function changePage(page) {
      currentPage.value = page
      localStorage.setItem('savePage', JSON.stringify(currentPage.value))
    }

    onMounted(() => {
      const storedValue = sessionStorage.getItem('loginYN');
      const storedUID = sessionStorage.getItem('uID');
      user_name.value = sessionStorage.getItem('user_name');
      const savedPage = localStorage.getItem('savePage')
      if (storedValue == 'true' && storedUID > 0) {
        isLogin.value = true;
        uid.value = storedUID;
        fetchList();
      } else {
        isLogin.value = false;
      }
      if (savedPage) {
        currentPage.value = JSON.parse(savedPage)
      }
    });

    return {
      login,
      logout,
      isLogin,
      uid,
      user_name,
      user_password,
      search_by,
      search_text,
      pushProject,
      projectList,
      fetchList,
      filteredResults,
      downloadFile,
      formatCount,
      currentPage,
      paginatedData,
      totalPages,
      visiblePages,
      changePage,
      itemsPerPage,
      mapEngineName
    };
  },
};
</script>


<template>
  <div class="tc project_list">
    <div class="container">
      <template v-if="isLogin">
        <div class="user_info">
          <p>안녕하세요 <span>{{ user_name }}</span>님!</p>
          <button @click="logout">로그아웃</button>
        </div>
        <div class="search_wrap">
          <div class="field_wrap">
            <fieldset>
              <select v-model="search_by" @change="fetchList">
                <option value="project_name">프로젝트명</option>
                <option value="project_code">프로젝트코드</option>
                <option value="trans_engine">번역엔진</option>
                <option value="src_lang">출발어</option>
              </select>
            </fieldset>
            <fieldset>
              <input type="text" v-model="search_text" placeholder="검색어를 입력해주세요." />
            </fieldset>
          </div>
          <div style="display: flex; justify-content: center; align-items: flex-end; gap: 8px;">
            <router-link v-if="uid == 5 || uid == 6 || uid == 2" to="promptManager" class="go_prompt_manager">프롬프트 관리</router-link>
            <router-link to="/translate-creation" class="project_btn">프로젝트 추가</router-link>
          </div>
        </div>
        <p class="total">total : {{ filteredResults.length }}</p>
        <div class="list_wrap">
          <div class="th">
            <p class="index">No.</p>
            <p class="project_code">프로젝트코드</p>
            <p class="project_name">프로젝트명</p>
            <p class="language">언어</p>
            <p class="count">분량</p>
            <p class="engine">번역엔진</p>
            <p class="date">날짜</p>
            <p class="file">원본</p>
            <p class="file">번역본</p>
            <p class="file">용어집</p>
          </div>
          <template v-if="projectList.length == 0">
            <p class="nodata">프로젝트가 없습니다.</p>
          </template>

          <template v-else>
            <div class="list" v-for="(item, index) in paginatedData" :key="index">
              <p class="index" @click="pushProject(item.project_code)">{{ (index + 1) + (currentPage - 1) * 10 }}</p>
              <p class="project_code" @click="pushProject(item.project_code)">{{ item.project_code }}</p>
              <p class="project_name" @click="pushProject(item.project_code)">{{ item.project_name }}</p>
              <p class="language" @click="pushProject(item.project_code)">{{ item.src_lang }}-{{ item.tgt_lang }}</p>
              <p class="count" @click="pushProject(item.project_code)">{{ formatCount(item.count_num) }}{{
        item.count_unit == 'char' ? '자' : '단어' }}</p>
              <p class="engine" @click="pushProject(item.project_code)">{{ mapEngineName(item.trans_engine) }}</p>
              <p class="date" @click="pushProject(item.project_code)">생성일 : {{ item.created_at }}<br />수정일 : {{
        item.updated_at }}</p>
              <a class="file" :href="downloadFile(item.project_code, 'origin')"><img
                  src="../assets/img/download_icon.png" /></a>
              <a class="file" :href="downloadFile(item.project_code, 'trans')"><img
                  src="../assets/img/download_icon.png" /></a>
              <a class="file" :href="downloadFile(item.project_code, 'glo')"><img
                  src="../assets/img/download_icon.png" /></a>
            </div>
            <div class="pagination">
              <button class="page-btn" @click="changePage(currentPage - 1)" :disabled="currentPage === 1"><img
                  src="../assets/img/pagination_arrow.png" /></button>
              <template v-for="(page, index) in visiblePages" :key="index">
                <button class="page-btn" v-if="page !== '...'" @click="changePage(page)"
                  :class="{ active: page === currentPage }">{{ page }}</button>
                <span v-else>...</span>
              </template>
              <button class="page-btn" @click="changePage(currentPage + 1)" :disabled="currentPage === totalPages"><img
                  class="rotate" src="../assets/img/pagination_arrow.png" /></button>
            </div>
          </template>
        </div>
      </template>

      <template v-else>
        <div class="login_wrap">
          <p>로그인</p>
          <input type="text" v-model="user_name" placeholder="아이디를 입력해주세요" @keydown.enter="login"/>
          <input type="password" v-model="user_password" placeholder="비밀번호를 입력해주세요" @keydown.enter="login"/>
          <button @click="login">로그인</button>
        </div>
      </template>
    </div>
  </div>
</template>
